<style lang="sass" scoped>

</style>
<template lang="pug">
.bg-white.shadow-sm(style='min-height: calc(90vh)')
  h4.border-bottom.p-4.mb-0.text-primary 고객/주문 취소내역
  vue-good-table.table-default(
    ref='table'
    styleClass='vgt-table striped hover'
    :columns='columns'
    :rows='rows'
    max-height='80vh'
    :fixed-header='true'
    compactMode
    mode='remote'
    :search-options="searchOptions"
    :pagination-options='paginationOptions'
    :totalRows='total_count'
    @on-search="onSearch"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    :sort-options='sortOptions'
    :isLoading.sync="isLoading"
  )
    template(slot='loadingContent')
      b-overlay(show variant='light' opacity='0.85' blur='0')
    template(slot='table-row' slot-scope='props')
      span(v-if='props.column.field==`action`')
        a.p-2(:href='`/property/${property_id}/order/all/${props.row.id}`' target='_blank') 조회
      span(v-else) {{props.formattedRow[props.column.field]}}


  //- table.table
    thead
      tr
        th ID
        th 고객명
        th 연락처
        th 이메일
        th 주문메모
        th 생성일
        th 변경일
        th 취소일
        th
    tbody
      tr(v-for='row in rows')
        td {{row.id}}


</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      columns: [
        {
          label: '번호',
          field: 'id',
          sortable: false,
          tdClass: (row) => {
            if (row.status_color) {
              return 'order-status-bordered-' + row.status_color
            } else {
              return 'order-status-default'
            }
          },
        },
        {
          label: '상태',
          field: 'status_text',
          tdClass: this.columnStyle,
          // filterOptions: {
          //   enabled: true,
          //   filterDropdownItems: ['와우']
          // }
        },
        {
          label: '고객명',
          field: 'name',
          tdClass: this.columnStyle,
        },
        {
          label: '연락처',
          field: 'phone',
          tdClass: this.columnStyle,
        },
        {
          label: '이메일',
          field: 'email',
          tdClass: this.columnStyle,
          sortable: false,
        },
        {
          label: '생성일',
          field: 'created_at',
          tdClass: this.columnStyle,
        },
        {
          label: '변경일',
          field: 'updated_at',
          tdClass: this.columnStyle,
        },
        {
          label: '취소일',
          field: 'canceled_at',
          tdClass: this.columnStyle,
        },
        {
          label: '고객메모',
          field: 'customer_memo',
          tdClass: this.columnStyle,
          sortable: false,
        },
        {
          label: '주문메모',
          field: 'memo',
          tdClass: this.columnStyle,
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          tdClass: this.columnStyle,
          sortable: false,
        },
      ],
      rows: [],
      total_count: 0,
      paginationOptions: {
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        setCurrentPage: 1,
        perPageDropdown: [10, 30, 100],
        dropdownAllowAll: false,
      },
      isLoading: false,
      sortOptions: {
        enabled: true,
        initialSortBy: [
          // {
          //   field: 'created_at',
          //   type: 'desc',
          // },
        ]
      },
      searchOptions: {
        enabled: true,
        trigger: '',
      },
      serverParams: {
        columnFilters: {
        },
        sort: [
          // {
          //   field: 'created_at',
          //   type: 'desc',
          // }
        ],
        page: 1,
        perPage: 10
      },
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/orders/canceled`, {
          params: this.serverParams,
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '정보 가져오기 실패')

        this.rows = r.data.rows
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.load();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.load();
    },

    onSortChange(params) {
      // console.log(params)
      // const sort = params.map(e => {
      //   return {
      //     type: e.type,
      //     field: e.field,
      //   }
      // })
      this.updateParams({
        sort: params,
      });
      this.load();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.load();
    },

    onSearch(params) {
      console.log(this.sf_id)
      this.updateParams(params);
      this.load();
    },
    async submit() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}`, this.form)
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장 실패')

        this.load()
        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.'
        })
        this.$store.dispatch('status flows', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    }
  },
}
</script>
